import React from 'react';
import classnames from 'classnames/bind';

import Header from '../Header';
import Socials from '../Socials';

import styles from './Layout.module.scss';
const cx = classnames.bind(styles);

const Layout = ({ children }) => (
  <div className={cx('layout')}>
    <Header />
    {children}
    <Socials />
  </div>
);

export default Layout;
