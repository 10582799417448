import { useCallback, useEffect, useState } from 'react';

const useDimensions = () => {
  const [dimensions, setDimensions] = useState({
    windowWidth: 0,
    windowHeight: 0,
  });

  const updateDimensions = useCallback(() => {
    if (typeof window !== 'undefined') {
      setDimensions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    }
  }, []);

  useEffect(() => {
    updateDimensions();

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateDimensions);
      return () => window.removeEventListener('resize', updateDimensions);
    }

    return () => {};
  }, [updateDimensions]);

  return dimensions;
};

export default useDimensions;
