import React from 'react';
import classnames from 'classnames/bind';
import { Github, Linkedin, Gmail, Telegram, Whatsapp, Medium } from '@icons-pack/react-simple-icons';

import styles from './Socials.module.scss';
const cx = classnames.bind(styles);

const Socials = () => (
  <div className={cx('socials')}>
    <a href="https://github.com/narekkeryan" target="_blank" rel="noopener noreferrer">
      <Github />
    </a>
    <a href="https://www.linkedin.com/in/narek-keryan-b79071112/" target="_blank" rel="noopener noreferrer">
      <Linkedin />
    </a>
    <a href="https://medium.com/@narekkeryan" target="_blank" rel="noopener noreferrer">
      <Medium />
    </a>
    <a href="mailto:narek.keryan@gmail.com" target="_blank" rel="noopener noreferrer">
      <Gmail />
    </a>
    <a href="https://t.me/narekkeryan" target="_blank" rel="noopener noreferrer">
      <Telegram />
    </a>
    <a href="tel:+37494940685" target="_blank" rel="noopener noreferrer">
      <Whatsapp />
    </a>
  </div>
);

export default Socials;
