import React from 'react';
import { Link } from 'gatsby';
import classnames from 'classnames/bind';

import styles from './Header.module.scss';
const cx = classnames.bind(styles);

const Header = () => (
  <div className={cx('header')}>
    <div className={cx('headerSection')}>
      <div className={cx('headerSectionLogo')}>
        <Link to="/" className={cx('headerSectionUrl')}>nkgdev</Link>
      </div>
    </div>
    <div className={cx('headerSection')}>
      <div className={cx('headerSectionItem')}>
        <Link to="/about" className={cx('headerSectionUrl')}>About</Link>
      </div>
      <div className={cx('headerSectionItem')}>
        <Link to="/services" className={cx('headerSectionUrl')}>Services</Link>
      </div>
      <div className={cx('headerSectionItem')}>
        <Link to="/portfolio" className={cx('headerSectionUrl')}>Portfolio</Link>
      </div>
      <div className={cx('headerSectionItem')}>
        <Link to="/blog" className={cx('headerSectionUrl')}>Blog</Link>
      </div>
      <div className={cx('headerSectionItem')}>
        <Link to="/contact" className={cx('headerSectionUrl')}>Contact</Link>
      </div>
    </div>
  </div>
);

export default Header;
